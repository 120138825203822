import { List, Typography as T } from 'antd'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../components/Layout'
import MenuCategory from '../components/MenuCategory'
import SEO from '../components/SEO'
import Tags from '../components/Tags'
import { getImageInfo, localizedSlug } from '../gatsby/NodeHelpers'
import i18n from './i18n'

const BlogPage = ({ location, data: { blogPosts }, pageContext: { locale, total, currentPage, limit } }: any) => {
  const renderItem = (item: any) => {
    const blogImage = getImageInfo(item.node.data.body)
    return (
      <List.Item
        key={item.node.uid}
        actions={[
          <Tags key={item.node.uid} type="blog" tags={item.node.tags} locale={locale} className="sg-tag-breadcrumb" />,
          item.node.last_publication_date,
        ]}
      >
        <List.Item.Meta
          title={
            <Link to={localizedSlug({ lang: locale, uid: `blog/${item.node.uid}` })}>
              <T.Title level={3}>{item.node.data.title.text}</T.Title>
            </Link>
          }
          description={
            <div>
              <div className="sg-img">
                <Img title={blogImage.caption} fluid={blogImage.fluid} />
              </div>
              <T.Paragraph style={{ fontSize: 15, marginTop: 15 }} ellipsis={{ rows: 5 }}>
                {item.node.data.content.text.substr(0, 1500)}
              </T.Paragraph>
            </div>
          }
        />
      </List.Item>
    )
  }
  return (
    <Layout locale={locale} location={location}>
      <SEO location={location} locale={locale} title={i18n[locale].blogTitle} />
      <MenuCategory type="blog" locale={locale} title={i18n[locale].blogTitle} />
      <List
        className="sg-blog-list"
        itemLayout="vertical"
        size="default"
        pagination={{
          onChange: page => {
            const uriPage = page === 1 ? '' : page
            navigate(localizedSlug({ lang: locale, uid: `blog/${uriPage}` }))
          },
          current: currentPage,
          pageSize: limit,
          total,
        }}
        dataSource={blogPosts.edges}
        renderItem={renderItem}
      />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query BlogBySlug($locale: String!, $skip: Int!, $limit: Int!) {
    blogPosts: allPrismicBlogPosts(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $locale } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...IndexBlogBlockFragment
        }
      }
    }
  }
`
